import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ pagename, keywords }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const desc = 'Quantitative statistics and Artificial Intelligence based trading signals for traders who want persistent market edge.'
  var keyw = 'trading, forecast, artificial intelligence, AI, forex, crypto, equities, commodities, prediction, profit'

  const metaDescription = site.siteMetadata.description || desc
  const defaultTitle = site.siteMetadata?.title || "EdgeForecaster App"
  if(keywords.length > 0) { keyw = keywords; }

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={defaultTitle + ' | '+pagename}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
            name: 'keywords', 
            content: keywords
        },
        {
          property: `og:title`,
          content: defaultTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: defaultTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat([])}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: 'Quantitative statistics and Artificial Intelligence based trading signals for traders who want persistent market edge.',
  keywords: 'trading, forecast, artificial intelligence, AI, forex, crypto, equities, commodities, prediction, profit'
}

SEO.propTypes = {
  pagename: PropTypes.string.isRequired,
  keywords: PropTypes.array.isRequired
}

export default SEO